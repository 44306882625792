<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";
// import Swal from "sweetalert2";

/**
 * Calendar component
 */
export default {
  props: {
    initialEvents: { type: Array, default: () => [] }, //the inital events for calendar
  },
  created() {
    //console.log(this.initialEvents);
    this.calendarOptions.initialEvents = this.initialEvents;
    // console.log(this.calendarOptions.initialEvents);
    this.view = true;
  },
  components: { FullCalendar },
  data() {
    return {
      //dummy data
      //   categories: [
      //     {
      //       name: "Danger",
      //       value: "bg-danger",
      //     },
      //     {
      //       name: "Success",
      //       value: "bg-success",
      //     },
      //     {
      //       name: "Primary",
      //       value: "bg-primary",
      //     },
      //     {
      //       name: "Info",
      //       value: "bg-info",
      //     },
      //     {
      //       name: "Dark",
      //       value: "bg-dark",
      //     },
      //     {
      //       name: "Warning",
      //       value: "bg-warning",
      //     },
      //   ],

      // calendarEvents: [
      //   {
      //     id: 1,
      //     title: "All Day Event",
      //     start: new Date().setDate(new Date().getDate() + 2),
      //     className: "bg-warning text-white",
      //   },
      //   {
      //     id: 2,
      //     title: "Long Event",
      //     start: new Date().setDate(new Date().getDate() - 5),
      //     end: new Date().setDate(new Date().getDate() - 3),
      //     className: "bg-success text-white",
      //   },
      //   {
      //     id: 3,
      //     title: "Lunch",
      //     start: new Date().setDate(new Date().getDate() + 2),
      //     className: "bg-info text-white",
      //   },
      //   {
      //     id: 4,
      //     title: "Birthday Party",
      //     start: new Date().setDate(new Date().getDate() + 4),
      //     className: "bg-primary text-white",
      //   },
      // ],
      /////////
      calendarOptions: {
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        buttonText: {
          today: this.$t("today"),
          month: this.$t("month"),
          week: this.$t("week"),
          day: this.$t("day"),
          list: this.$t("list"),
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        initialEvents: [],

        eventClick: this.editEvent,
        dateClick: this.dateClicked,

        // hiddenDays: [5, 6],
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
      },
      currentEvents: [],
    };
  },
  mounted() {
    this.calendarOptions.initialEvents = this.initialEvents; //to import the inital events given prop to the calendar
  },
  methods: {
    /**
     * Modal open for edit event
     */
    editEvent(info) {
      //console.log(info.event.id);
      this.$router.push({ path: `/consultant/${info.event.id}` });
    },
    dateClicked(info) {
      this.$emit("dateClicked", info);
    },

    /**
     * Show successfull Save Dialog
     */
    // successmsg() {
    //   Swal.fire({
    //     position: "center",
    //     icon: "success",
    //     title: "Event has been saved",
    //     showConfirmButton: false,
    //     timer: 1000,
    //   });
    // },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <!-- the full calendar package passing calendaroptions and renders after the initial events imported cuz the package dont re-render in initail event changes-->
              <FullCalendar
                v-if="view"
                ref="fullCalendar"
                :options="calendarOptions"
              ></FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
