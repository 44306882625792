<script>
import Calendars from "@/components/elements/Calendars.vue";
import { GetAllConsultant, AddBusyTime } from "@/services/consultant.js";
import moment from "moment";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";

/**
 * Calendar component
 */
export default {
  page: {
    title: "consultant Calendar",
    meta: [{ name: "description" }],
  },
  components: { Calendars, Layout, PageHeader },
  created() {
    // console.log(this.calendarOptions.initialEvents);
    this.getCallendarEvents();
  },
  data() {
    return {
      title: this.$t("consultant_calendar"),
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("menuitems.consultant.text"),
        },
        {
          text: this.$t("calendar"),
          active: true,
        },
      ],
      calendarData: [],
      addData: { start: "", end: "", title: "" },
    };
  },

  methods: {
    getCallendarEvents() {
      GetAllConsultant().then((data) => {
        console.log(data);
        data.forEach((element) => {
          this.calendarData.push({
            id: element.id,
            title: element.title, //Replace to 'Appointment_Title'
            start: moment(
              element.start,
              "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
            ).toISOString(), //replace created_time to date.startDate
            end: moment(
              element.end,
              "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
            ).toISOString(), //replace updatedAt to date.endDate
            className:
              element.totalAmount || element.clientID
                ? "bg-success text-white"
                : "bg-danger text-white",
          });
        });
      });
    },
    createBusyTime() {
      AddBusyTime(
        new Date(this.addData.start),
        new Date(this.addData.end),
        this.addData.title
      ).then(() => {
        this.successmsg();
        this.$bvModal.hide("modal-backdrop-add");
        this.getCallendarEvents();
        this.addData.start = "";
        this.addData.end = "";
        this.addData.title = "";
      });
    },
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Busy Time Has Been Added",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    deleteEvent() {
      console.log("event delete");
    },
    dateClicked(info) {
      // console.log(info);
      this.addData.start = moment(info.date).format("YYYY-MM-DDTHH:mm");
      this.addData.end = moment(info.date).format("YYYY-MM-DDTHH:mm");
      this.$bvModal.show("modal-backdrop-add");
    },
    checkdates() {
      const startDate = moment(this.addData.start, "YYYY-MM-DDTHH:mm");
      const startDatePlusOneHour = startDate
        .clone()
        .add(1, "hour")
        .format("YYYY-MM-DDTHH:mm");
      console.log(startDatePlusOneHour, startDate); // console.log(startDate, startDateClone);
      const endDate = moment(this.addData.end, "YYYY-MM-DDTHH:mm");
      if (startDate.isAfter(endDate)) {
        this.msg("start is after end", "error");
      } else if (startDate.isSame(endDate)) {
        this.msg("start is same as end", "error");
        // } else if (startDateClone.isBefore(endDate)) {
        //   this.msg("Busy time Must Be At Least One Hour", "error");
        // } else if (startDateClone.isAfter(endDate)) {
        //   this.msg("Busy time Must Be At Least One Hour", "error");
      } else {
        this.msg("", "success");
      }
    },
    msg(msg, icon) {
      Swal.fire({
        position: "center",
        icon: icon,
        title: msg,
        showConfirmButton: false,
        timer: 1000,
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Calendars
      v-if="calendarData.length > 0"
      :initial-events="[...calendarData]"
      @event_created="createBusyTime"
      @event_delete="deleteEvent"
      @dateClicked="dateClicked"
    />
    <b-modal
      no-close-on-backdrop-add
      id="modal-backdrop-add"
      :title="$t('add_busy_date')"
      centered
      title-class="font-18"
    >
      <!-- disable modal default Buttons -->
      <template #modal-footer>
        <div></div>
      </template>
      <!-- modal form -->
      <form @submit.prevent="createBusyTime">
        <label for="date-time-start">{{ $t("start_date") }}</label>
        <input
          type="text"
          :placeholder="$t('title')"
          v-model="addData.title"
          class="form-control mb-4"
          required
        />
        <b-form-input
          id="date-time-start"
          v-model="addData.start"
          type="datetime-local"
          required
        ></b-form-input>
        <label for="date-time-end">{{ $t("end_date") }}</label>
        <b-form-input
          id="date-time-end"
          v-model="addData.end"
          type="datetime-local"
          required
        ></b-form-input>
        <!-- buttons for modal  -->
        <b-row
          ><b-col cols="8"></b-col>
          <b-col cols="2" style="margin-top: 10px"
            ><b-button @click="$bvModal.hide('modal-backdrop-add')">{{
              $t("cancel")
            }}</b-button>
          </b-col>
          <b-col cols="2" style="margin-top: 10px">
            <b-button variant="primary" type="submit">{{ $t("ok") }}</b-button>
          </b-col></b-row
        >

        <!-- End buttons -->
      </form>
      <!-- end form -->
    </b-modal>
  </Layout>
</template>
